




























































import { addBasePath } from '~/helpers/addBasePath';
import { defineComponent } from '@nuxtjs/composition-api';
import { SfImage } from '@storefront-ui/vue';

export default defineComponent({
  components: {
    SfImage,
  },
  setup() {
    const navigation = [
      { name: 'Adresy sklepów', href: '/adresy-sklepow', target: '_self' },
      { name: 'Sprzedaż hurtowa', href: '/przedstawiciele-handlowi', target: '_self' },
      { name: 'Info-Karta', href: 'https://info-karta.pl/', target: '_blank' },
      { name: 'Kariera', href: '/kariera', target: '_self' },
      { name: 'Kontakt', href: '/kontakt', target: '_self' },
      { name: 'O nas', href: '/i/o-nas', target: '_self' },
      { name: 'Programy lojalnościowe', href: '/programy-lojalnosciowe', target: '_self' },
    ];
    return {
      navigation,
      addBasePath,
    };
  },
});
